.secondary-button,
.destructive-button {
  @apply p-custom1 rounded-md text-sm;
}
.secondary-button {
  @apply bg-transparent text-customPurple-900 border border-customPurple-900;
}

.destructive-button {
  @apply bg-customRed-900 text-white;
}

.secondary-hover-effect:hover {
  @apply text-customPurple-800 border border-customPurple-800;
}
