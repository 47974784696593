.custom-select {
  position: relative;
  display: inline-block;
}

.select-label {
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 99999;
}

.select-options div {
  padding: 8px;
  cursor: pointer;
}

.select-options div:hover {
  background-color: #f2f2f2;
}
