.custom-time-picker .rc-time-picker-input {
  font-size: 14px;
  padding: 20px 8px;
  border-radius: 8px;
  border: 1px solid rgb(209 213 219) !important;
  color: rgb(17 24 39);
}
.customPicker {
  border-color: hsl(0, 0%, 80%);
}
