@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
  body {
    @apply bg-customGrey-200;
  }
  td,
  th {
    @apply p-2;
  }
  th {
    @apply text-left;
  }
}
