.ReactTable {
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  /* max-height: 500px; */
  font-size: 14px;
}

.ReactTable .rt-table {
  overflow: unset;
}

.ReactTable .rt-thead.-header {
  box-shadow: none;
  @apply bg-customGrey-100 text-customGrey-300 border rounded-t-lg;
  z-index: 20;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-right: 1px solid rgba(206, 202, 202, 0.2);
  font-size: 14px;
}

.rt-th {
  /* width: 10px; */
}
.ReactTable .rt-thead.-header .rt-tr {
  text-align: left;
}
.rt-tbody {
  background-color: white;
}
.rt-tr-group {
  /* padding: 0 10px; */
  min-height: 50px;
}

.ReactTable .rt-tbody .rt-td {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-right: 1px solid rgba(206, 202, 202, 0.2);
}

/* adjust the position of the header cells to align with the table cells */
.ReactTable .rt-thead.-header {
  position: sticky;
  top: 0;
  /* z-index: 1; */
}
